import { ThemeProvider } from "@emotion/react";
import { createTheme, Container, CssBaseline, Box, Typography, Button } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const theme = createTheme({
    palette: {
      background: {
        default: "#c3b5d7",
      },
    },
  });
  
export default function ConfirmAccount(){
    return (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <StaticImage
                src="../images/avatar-settings.png"
                alt="Zubcho Settings"
                placeholder="blurred"
                layout="fixed"
                width={200}
              />
              <Typography component="h1" variant="h5" sx={{ pt: 4 }}>
                Профилът е потвърден успешно
              </Typography>              
            </Box>
          </Container>
        </ThemeProvider>
      );
}